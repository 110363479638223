.nav
{
    display:flex;
}
nav
{
    margin-top: 0;
    width: 100%;
    text-align: right;
    height: 10vh;
    background-color:#5512ac  ;
   
}
.divmobile
{
    display: none;
}
.ulmobile
{
    display: none;
}
.fad fa-bars
{
    width: 40px;
}
.anav
{
    
    font-size: 20px;
    font-family: Roboto,"Helvetica Neue";
    text-decoration: none;
    color: white;
    display: block;
    padding: 0 5px;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0.1px 0.1px 0.3px black;
}
li
{
  
    list-style: none;
    
   
}

.anav:hover
{
    box-shadow: 0px 0px 0px; 
    text-decoration: none;
}
.anav:active
{
    box-shadow: 0px 0px 0px;   
}
ul
{
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
   
    
}
.lienaccueil
{
    width: 103px;
   
}
.lienaccueil img
{
    height: 100%;
    border-radius: 100%;
    width: 100%;
    position: relative;
    
}
.fas fa-bars
{
    display: none;
}

@media screen and (max-width: 1400px)
{
    .anav
    {
        height:10vh;
        font-size: 23px;
    }
}

@media screen and (max-width: 590px)
{
    .lienaccueil img
    {
        display: none;
    } 

    .fa-bars
{
    display: block;
    height: 100%;
}
.lienaccueil
{
    text-align: left;
    font-size: 30px;
    color: white;
}
     .anav
    {
      display: none;
    
    }
    
    .ulmobile
    {
        position: relative;
        top: 10vh;
        height: 85vh;
        background: #f5f7fa;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .ulmobile li
    {
        text-align: left;
        background: #f5f7fa;
        
    }
    .amobile
    {
        margin: 30px 15px 10px 15px;
        border-bottom: 1px gray solid;
        width: 100%;
        display: block;
        margin-right: 15px;
    }
    .divmobile
{
    display: block;
}
}