.lmj-plant-list {
	list-style-type: none;
	padding: 32px;
	display: flex;
	flex-wrap: wrap;
	max-width: 800px;
}

.lmj-plant-item {
	border: solid 2px black;
	border-radius: 10px;
	height: 150px;
	width: 150px;
	margin: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
}

.lmj-sales {
	position: absolute;
	right: 0px;
	top: 10px;
	font-weight: 500;
	color: white;
	padding: 5px 10px;
	background-color: #31b572;
}