.changesens
{
    display: flex;
    justify-content: center;
}

.changesensdiv
{
    display: flex;
    align-items: center;
    margin: 0 15px;
}

