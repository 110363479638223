.skills
{
  position:relative;
  top: -200px; 
  background-color: white;
  width: 85%;
  margin: 0 auto;
  
border-radius: 12px;
  box-shadow: 0 8px 8px 0 rgb(233 240 243 / 50%), 0 0 0 2px #e6ecf8;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  
  font-family: 'Roboto', sans-serif;
    
}
.lienstravaux
{
    color: #7510F7 !important;
    font-size: 30px;  
}
.columnskills:last-child
{
    border-left:1px solid #E6ECF8;
}
.columnskills:first-child
{
    border-right:1px solid #E6ECF8;
}
.titleskills
{
    text-align: center;
    color: #7510F7 !important;
    padding-top: 60px;
}
.pskills
{
    font-size: 18px;
    text-align: center;
    margin: 0 50px;
    font-size: 25px;
    height: 10%;
}
.titlelanguage
{
    margin-top: 50px;
    margin-bottom: 8px;
    font-size: 25px;
    line-height: 30px;
    font-weight: 400 !important;
    color: #7510F7 !important;
    text-align: center !important;
}

.language
{
    text-align: center !important; 
    font-size: 25px;
    text-align: center; 
    height: 5%;
}
.tlanguage
{
    text-align: center !important; 
    font-size: 25px;
    text-align: center;   
}
@media screen and (max-width: 1400px)
{
    .pskills
    {
        font-size: 25px;
        height: 15%;
    }
 .skills
 {
    box-shadow: 0 5px 5px 5px rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8;
 }   
.columnskills:last-child
{
    border-right: 1px solid #E6ECF8;
}
}

@media screen and (max-width: 590px)
{

    .skills
    {
      display: flex;
      flex-direction: column;
      top: -100px;
    }
    .columnskills:first-child {
        border-right: none;
    }
.language
{
    font-size: 15px;
}
.titlelanguage
{
    font-size: 25px;
}
}
