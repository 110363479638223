.lmj-plant-item-cover {
	height: 220px;
	width: 220px;
	border-radius: 20px;
}

.lmj-plant-item {
	margin: 10px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	text-transform: capitalize;
}