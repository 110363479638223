.lmj-layout-inner {
	display: flex;
	flex-direction: row;
}
.lmj-title
{
	text-align: center;
	color: #141c3a;
	font-weight: 800;
	font-size: 3rem ;
	font-family: 'Roboto', sans-serif;
	margin-top: 200px;
}
.lmj-logo
{
	max-width: 300px;
	
	border-radius: 100%;
}
.divimage
{
	text-align: center;	
}
@media screen and (max-width: 590px)
{
   
    .lmj-title
    {
        font-size: 2rem;
		margin-top: 110px;
    }
}