

h2{
    text-align: center;
    font-size: 50px;
    margin-bottom: 0;
}

input
{
    font-size: 30px;
    text-align: center;
    margin: 10px 0;
}




    h2{
        
        font-size: 25px;
        
    }
    input
{
    font-size: 15px;
   width: auto;
    margin: 1px 0;
}
.infodebase 
{
    display: flex;
    flex-direction: column;
}
