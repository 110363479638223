.changsens
{
    display: flex;
    justify-content: center;
    font-size: 25px;
    
}
.heure
{
   background-color: #7510F7;
   height:90vh ;
   width: 100%;
   padding-top: 40px;
   padding-right: 15px;
   
}
.heurediv
{
    background-color: white;
    padding: 30px;
    border-radius: 5%;
    border:1px solid gray;
    box-shadow:10px 5px 5px #5512ac ;
    
}
.trname
{
    width: 250px;
    overflow:clip;
    

}

.bodyreservation
{
    background: #7510F7;
    width: 100%;
    height: 90vh;
   
    
}
.reservation
{
    width: 75%;
    margin: auto;
    background-color: white;
    height: 80vh;
    padding-top: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 5%;
    border:1px solid gray;
    box-shadow:10px 5px 5px #5512ac ;
}
.rreservation
{
    display: flex;
    justify-content: space-between;
}
.mobile
{
display: none;

}
.pc
{
display: block;

}

table
{
  
    text-align: center;
    font-size: 35px;
    border-collapse: collapse;
    margin: auto;
    

}
.tr
{
    cursor: pointer;
}
tr
{
  width: 100%;
    text-align: center;
    border: 3px solid black;
    margin: 0;
    display: block;
    
}
td
{
    margin: 0;
    border-collapse: collapse;
    width: 120px;
}
.changsens p
{
    font-size: 35px;
}
.titreheure
{
    font-size: 35px; 
    text-align: center;
}
.h2reservation
   {
       font-size: 50px;
       margin-bottom: 25px;
       text-align: center;
   }
@media screen and (max-width: 1400px)
{
   .h2reservation
   {
       font-size: 45px;
       margin-bottom: 55px;
       margin-top: 70px;
   }
   .changsens
   {
       margin-bottom: 35px;
   }
   .changsens p
   {
       font-size: 30px;
   }
   .changesensdiv
   {
       align-items: flex-start;
   }
   
    .reservation
    {
       margin-left: 30px;
       width: 65%;
       margin-right: 0px;
       
    }
    .titreheure
    {
        font-size: 25px;
    }
    .trname
{
    width: 150px;
    overflow:clip;
    

}
    
    table
    {
        font-size: 20px;
        margin: 0 30px;
    }
    .mobile
{
display: block;



}
.pc
{
display: none;

}
   
}
@media screen and (max-width: 590px)
{
    .h2reservation
    {
        font-size: 25px;
        margin-bottom: 15px;
        margin-top: 5px;
    }
    .reservation
    {
       
    
     height: 65vh;
     width: 90%;
     margin: auto;
     margin-bottom:40px;
       
    }
    .heure
    {
        padding: 0;
    }
    .changsens p
    {
        font-size: 17px;
        margin-bottom: 0;
    }
    table
    {
        font-size: 11.9px;
        margin-top: 0;
        
    }
    .titreheure
{
    font-size: 25px; 
    
}

.mobile
{
display: block;
font-size: 12px;


}
.pc
{
display: none;

}
.rreservation
{
    flex-direction: column;
    justify-content: center;
    background-color: #7510F7;
    min-height: 90vh;
}
.bodyreservation
{
    height: auto;
    padding-top: 5vh;
}
.heurediv
{
    width: 90%;
    margin: auto;
}


}

