.bleue
{
    height: 3200px;
    background-color: #7510F7;
    margin-top: 0;
}

.divworking img
{
    border: 1px solid gray;
    max-height: 50%;
}

.divworking
{
    position: relative;
    top: -3100px;
    background-color: white;
    width: 85%;
    margin: 0 auto;
    border-radius: 12px;
    box-shadow: 0 5px 5px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.bordure
{
    
    width: 100%;
    display: flex;
    justify-content: center;
    
   
}
.theworking
{
    text-align: center;
    width: 550px;
    margin-top: 30px;
    padding-bottom: 20px;
    height: 550px;
    border-bottom: 0.1px gray solid;
}
.acces
{
    margin: 0;
}
.info
{
    height: 35%;
}
.nom
{
    text-align: center;
    font-size: 45px;
}
.languep
{
    font-size: 25px;
}
.expliquep
{
    font-size: 20px;
}
.titleworking
{
    text-align: center;
    margin-bottom: 50px;
   padding-top: 30px;
    font-weight: 800;
    font-size: 60px;
    margin-top: 0;
}



@media screen and (max-width: 590px)
{
    .divworking img
    {
        max-height: 115%;
        max-width: 100%;
        margin: auto;
        margin-top: 20px;
    }
    .info
{
    height: auto;
}
    .languep
    {
        font-size: 20px;
    }
    .nom
    {
        font-size: 30px;
    }
    .titleworking
    {
        font-size: 2.2rem; ;
       margin-bottom: 15px;
    }
    .divworking
    {
        display: flex;
        flex-direction: column;
       justify-content: auto;
        
    }
    .theworking
    {
        width: 100%;
        height: 500px;
    }

.theworking
{
    margin-bottom: 30px;
}
}
