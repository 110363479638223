
.presentation
{
    background-color: #7510F7;
    color: #fff;   
    text-align: center;
    margin-bottom: 0;
    height: 800px;
}

.ppresentation
{
    width: 1100px;
    margin: auto;
    font-family: 'Roboto', sans-serif;
    font-size: 2.6rem ;
}

.lienpresentation
{
   color: black; 
   font-size: 45px;
}
.lienpresentation:hover
{
   color:#A3E7D6;
   
}
.tpresentation
{
    font-size: 3.2rem ;
    font-family: 'Roboto', sans-serif;
    margin-top: 0;
    padding-top: 110px;
    margin-bottom: 17px;

    
}
@media screen and (max-width: 1400px)
{
    .ppresentation
    {
       font-size: 2rem;
       width: 700px;
    }
    .tpresentation
    {
        padding-top: 50px;
        
        font-size: 2.7rem;
        width: 950px;
        margin: auto;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 590px)
{
    .tpresentation
    {
        font-size: 1.5rem ;
       margin-bottom: 30px;
       padding-top: 100px;
       width: 300px;
    }
    .ppresentation
{
    width: 250px;
    font-size: 1.4rem ;
}
.lienpresentation
{
    font-size: 1.5rem;
}


}
