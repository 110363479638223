.feedback
{
    height:65vh;
    width: 80%;
    border-radius: 5%;
    margin: auto;
    position: relative;
    top: 10vh;
    border:1px solid gray;
    box-shadow:10px 5px 5px gray;
    color:#1A1A1A;
    font-family: 'Roboto Slab', serif;
    
}

.feedback p
{
    text-align: center;
    width: 75%;
    margin: auto;
    padding: 15px 15px 0 15px;
    font-size: 35px;

}
.linkretour
{
    font-size: 35px;
    padding: 10px;
    margin-top: 55px;
    display: block;
}
@media screen and (max-width: 1400px)
{
    .feedback p
    {
        margin-top: 35px;
        font-size: 35px;
    
    }
    .linkretour
    {
        font-size: 35px;
        padding: 10px;
       
        margin-top: 55px;
        display: block;
    }
}

@media screen and (max-width: 590px)
{
    .feedback
    {
      
        top: 0vh;
      
        
    }
    .feedback p
{
    margin-top: 35px;
    font-size: 20px;

}
.linkretour
{
    font-size: 20px;
    padding: 10px;
    color: white;
    margin-top: 55px;
    display: block;
}
}