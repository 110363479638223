
.thereservation
{
    height:65vh;
    width: 80%;
    border-radius: 5%;
    margin: auto;
    position: relative;
    top: 10vh;
    border:1px solid gray;
    box-shadow:10px 5px 5px gray;
    color:#1A1A1A;
    font-family: 'Roboto Slab', serif;
    display: flex;
    
}

.inforeservation
{
    width: 30%;
    height: 100%;
    border-right:1px solid gray ;
}
.topinfo
{
    height: 50%; 
    border-bottom:1px solid gray ;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
}
.developpeur
{
    
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

}
.pdev
{
    margin: 0;
    font-family: ROBOTO;
}
.spandev
{
    color: #7510F7;
}

.developpeur img
{
   position: relative;
    width: 40%;
    height: 60%;
    margin-right: 10px;
}
.backinfo h2
{
    margin-left: 50px;
    font-size: 20px;
}
.formulaire h2
{
    margin-left: 50px;
    font-size: 20px;
    margin-bottom: 15px;
}
.backinfo p
{
    margin-left: 50px;
}
.divform
{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}
.divformt
{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-top: 40px;
}

input
{
    box-sizing: border-box;
    padding: 5px 7px;
    color: rgb(26, 26, 26);
   
    background: #ffffff;
    border: 1px solid #d0d0d0;
    border-radius: 8px;
}

textarea
{
    box-sizing: border-box;
    padding: 10px 14px;
    color: rgb(26, 26, 26);
    background: #ffffff;
    border: 1px solid #d0d0d0;
    border-radius: 8px;  
    margin-top: 10px;
    
}

.boutons
{
    text-align: center;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-height: 44px;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 40px;
    margin-top: 15px;
    
}
button
{
    font-size: 15px;
   
}

@media screen and (max-width: 1400px)
{
    .formulaire h2
    {
        margin-top: 30px;
        font-size: 30px;
    }
    .divform
    {
        margin-top: 40px;
        font-size: 20px;

    }
    .divformt
    {
        font-size: 20px;
    }
    .boutons
    {
        margin-top: 35px;
    }
    button
    {
        font-size: 20px;
    }
    .inforeservation
    {
        font-size: 18px;
    }
}
@media screen and (max-width: 590px)
{
     .topinfo
     {
         display: none;
     }
    label
    {
        text-align: center;
    }
 
    .thereservation
    {
        background-color: white;
        border:1px solid #5512ac ;
        box-shadow:10px 5px 5px #5512ac ;
        top: 1px;
        margin-right: 0;
        position: auto;
        width: 100%;
       
    }
    .backinfo p
    {
        margin-left: 5px;
    }
    .backinfo h2
    {
        font-size: 1px;
        
    }
    .formulaire input 
    {
        max-width:180px;
        padding: 0;
    }
    .formulaire textarea
    {
        max-width:180px;
        height: 60px;
    }
    .formulaire h2
    {
        font-size: 1px;
        margin-left: 5px;
    }
    .developpeur img
{
   position: relative;
    width: 30%;
    height: 30%;
    margin-right: 0px;
    top: 1px;
}
.developpeur
{
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.divform
{
    margin-top: 10px;
    font-size: 16px;

}
.divformt
{
    font-size: 16px;
}
.boutons
{
    margin-top: 20px;
}
button
{
    font-size: 16px;
}
.inforeservation
{
    font-size: 14px;
    display: flex;
    align-items: center;
}
}
