.lmj-banner {
    color: black;
    text-align: right;
    padding: 32px;
    cursor:pointer;
    min-height: 80vh;
}
.contact
{
    background-color: #7510F7;
    color: white;
    width: 20%;
    text-align: center;
    height: 20%;
    border-radius: 100%;
    font-size: 25px;
    padding: 20px;
}
.lesimages img
{
    
    position: absolute;
}
.image1
{
    top:12%;
    right: 5%;
    width: 10%;
}

.image2
{
    top:12%;
    left: 5%;
    width: 10%;
}

.image3
{
    width: 3%;
    top:30%;
    right: 20%;
}

.image4
{
    top:30%;
    left: 20%;
    width: 3%;
}
.image5
{
    top:60%;
    left: 20%;
    width: 3%;
}
.image6
{
    top:60%;
    right: 20%;
    width: 3%;
}
.image7
{
    top:80%;
    left: 5%;
    width: 12%;
}
.image8
{
    top:80%;
    right: 5%;
    width: 12%;
}
@media screen and (max-width: 600px)
{
    .contact
    {
        
        width: 40%;
       margin: auto;
       
    } 
   
}